import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getAllEmergencies = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/emergencies?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const getOneEmergencies = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/emergencies/${id}`,
    config
  );
  return response;
};

const deleteEmergencies = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}api/emergencies/${id}`,
    config
  );
  return response;
};

const postEmergencies = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(
    `${BACKEND_URL}api/emergencies`,
    data,
    config
  );
  return response;
};
export default {
  getAllEmergencies,
  getOneEmergencies,
  postEmergencies,
  deleteEmergencies,
};
