import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import knowledgeService from "../../../services/knowledgeService";
import uploadService from "../../../services/upload";
import axios from "axios";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  TextField,
  FormHelperText,
  Button,
  Typography,
  CardMedia,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import RichTextEditor from "react-rte";
import { instanceToken } from "../../../utils/constant";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const UpdateKnowledge = ({ homeAlert }) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [preview, setPreview] = useState(null);
  const [titleEng, setTitleEng] = useState("");
  const [titleMM, setTitleMM] = useState("");
  const [parEng, setParEng] = useState("");
  const [parMM, setParMM] = useState("");
  const [image, setImage] = useState("");
  const [errors, setErrors] = useState({});

  const [knowledge, setknowledge] = useState(null);
  const [oldImageName, setOldImageName] = useState(null);
  const [values, setValues] = useState({
    title_en: "",
    title_mm: "",
    paragraph_en: "",
    paragraph_mm: "",
    image_data: "",
  });

  useEffect(() => {
    if (!knowledge) {
      fetchknowledge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (knowledge) {
      setValues({
        title_en: knowledge.title_en ?? "",
        image_data: knowledge.image_data ?? "",
        paragraph_en: knowledge.paragraph_en ?? "",
        title_mm: knowledge.title_mm ?? "",
        paragraph_mm: knowledge.paragraph_mm ?? "",
      });

      setPreview(knowledge.image_data);
      if (knowledge.image_data) {
        setOldImageName(
          knowledge.image_data.substring(
            knowledge.image_data.lastIndexOf("/") + 1,
            knowledge.image_data.lenght
          )
        );
      }
    }
  }, [knowledge]);

  const fetchknowledge = async () => {
    try {
      const res = await axios.get(
        `https://cblife.axra.app/dashboard/api/knowledges/${id}`
      );
      console.log(res);
      setknowledge(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const changeTitleEng = (event) => {
    setTitleEng(event.target.value);
  };
  const changeTitleMM = (event) => {
    setTitleMM(event.target.value);
  };
  const handleChangeText = (e) => {
    setParEng(e.target.value);
  };
  const handleChangeTextMM = (e) => {
    setParMM(e.target.value);
  };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          profile: "Image file size must be smaller than 10MB.",
        });
        return;
      }
      setPreview(URL.createObjectURL(img));
      setImage(img);
    }
  };

  const handleImgUpload = async (url, imageFile) => {
    try {
      await uploadService.uploadImage(url, imageFile);
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchImageUrl = async () => {
  //   try {
  //     const res = await uploadService.getImageUrl(instanceToken.token);
  //     if (res.data) {
  //       return {
  //         url: res.data.imageUploadUrl,
  //         name: `https://axra.sgp1.digitaloceanspaces.com/CBLife/${res.data.imageName}`,
  //       };
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteImage = async (fileName) => {
    try {
      await uploadService.deleteImage(instanceToken.token, {
        imageName: fileName,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const formData = new FormData();
  const handleUpdate = async () => {
    console.log("knowledge data is ", knowledge);
    setErrors({});
    let err = {};

    formData.append("title_en", titleEng);
    formData.append("title_mm", titleMM);
    formData.append("paragraph_en", parEng);
    formData.append("paragraph_mm", parMM);
    formData.append("image_data", image);
    console.log("formData", formData);

    formData.forEach((value, index) => {
      if (value === "") {
        err[index] = `${index} field is required`;
      }
    });

    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);

      // if (isImageChange) {
      //   if (oldImageName) {
      //     await deleteImage(oldImageName);
      //   }

      //   const img = imageFile;
      //   // const { url, name } = await fetchImageUrl();
      //   await handleImgUpload(url, img);
      //   data = { ...data, image: name };
      // } else {
      //   data = { ...data, image: knowledge.image };
      // }

      const response = axios.put(
        `https://cblife.axra.app/dashboard/api/knowledges`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("data are ............... ", response);
      setknowledge(response.data);
      homeAlert("knowledge Post have been updated.", false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  return (
    <>
      <>
        <div role="presentation" style={{ marginBlockEnd: "10px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/knowledges">Knowledges</Link>
            <Link to={`/knowledges/${id}`}>Knowledge (ID - {id})</Link>
            <span>Update</span>
          </Breadcrumbs>
        </div>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card sx={{ minWidth: "80vw" }}>
            {formData.forEach((value, index) => console.log(index))}
            <CardContent
              sx={{ display: "flex", pb: 0, flexDirection: "column" }}
            >
              <Box
                sx={{
                  maxWidth: "40vw",
                  display: "grid",
                  justifyContent: "center",
                  // bgcolor: "red",
                  ml: 33,
                  borderRadius: 2,
                  boxShadow: 2,
                }}
              >
                <CardMedia
                  component="img"
                  height="320"
                  image={preview}
                  sx={{ my: 2 }}
                />
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {/* image */}
                <FormControl sx={{ my: 2, maxWidth: 400 }}>
                  <Button
                    variant="contained"
                    component="label"
                    size="large"
                    sx={{ p: 2 }}
                  >
                    <PhotoCamera />
                    <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                    <input
                      hidden
                      onChange={changeFile}
                      accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                      type="file"
                    />
                  </Button>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  px: 3,
                  columnGap: 5,
                }}
              >
                {/* title Eng */}
                <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                  <TextField
                    id="title_en"
                    label="Title Eng"
                    value={titleEng}
                    onChange={changeTitleEng}
                    // error={errors[index]}
                    // FormHelperText={errors[index]}
                  />
                </FormControl>
                {/* title MM */}
                <FormControl sx={{ my: 2 }} variant="outlined">
                  <TextField
                    id="title_mm"
                    label="Title MM"
                    value={titleMM}
                    onChange={changeTitleMM}
                    // error={errors[index]}
                    // FormHelperText={errors[index]}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  flex: 2,
                  display: "flex",
                  flexDirection: "column",
                  px: 3,
                }}
              >
                <FormControl sx={{ my: 2 }} variant="outlined">
                  <TextField
                    sx={{ overflowY: "scroll", maxHeight: "20rem" }}
                    multiline
                    id="paragraph_en"
                    label="Paragraph Eng"
                    // value={values.address}
                    onChange={handleChangeText}
                    // error={errors[index]}
                    // FormHelperText={errors[index]}
                  />
                </FormControl>
                <FormControl sx={{ my: 2 }} variant="outlined">
                  <TextField
                    sx={{ overflowY: "scroll", maxHeight: "20rem" }}
                    multiline={Infinity}
                    id="paragraph_mm"
                    label="Paragraph MM"
                    // value={values.address}
                    onChange={handleChangeTextMM}
                    // error={errors[index]}
                    // FormHelperText={errors[index]}
                  />
                </FormControl>
              </Box>
            </CardContent>
            ;
            <CardActions sx={{ justifyContent: "end" }}>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleUpdate}
                sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
              >
                Update
              </LoadingButton>
            </CardActions>
          </Card>
        </Box>
      </>
    </>
  );
};

export default UpdateKnowledge;
