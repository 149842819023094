import React, { useEffect, useState } from "react";
import news from "../../../services/newService";
import { instanceToken } from "../../../utils/constant";
import DirectionsIcon from "@mui/icons-material/Directions";
//import Divider from "@mui/material/Divider";
//import IconButton from "@mui/material/IconButton";
import axios from "axios";
import {
  TablePagination,
  Breadcrumbs,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Paper,
  InputBase,
  Divider,
  IconButton,
  Avatar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import newService from "../../../services/newService";

export default function News() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [news, setNews] = useState(null);

  useEffect(() => {
    fetchNews(rowsPerPage, offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage]);

  const fetchNews = async (limit, offset, search) => {
    try {
      const res = await newService.getNews(instanceToken.token, limit, offset);
      parse(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    setNews(data.news);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!news) {
    return <em>Loading...</em>;
  }
  console.log("news data is ", news);
  return (
    <>
      <div
        role="presentation"
        style={{
          marginBlockEnd: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <span>News</span>
        </Breadcrumbs>
        {/* search*/}
        {/* <div>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 350,
            }}
          >
            

            <InputBase
              id="search-by-title"
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search By Title"
              type="search"
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="info"
              id="search-by-title"
              sx={{ p: "10px" }}
              aria-label="directions"
              // value={search}
              // onClick={handleSearch}
            >
              <DirectionsIcon />
            </IconButton>
          </Paper>
        </div>  */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          sx={{ my: 3 }}
          variant="contained"
          onClick={() => navigate("/createNew")}
        >
          Create
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              {/* <TableCell>Image</TableCell> */}
              <TableCell>Title Eng</TableCell>
              <TableCell>Title MM</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Paragraph Eng</TableCell>
              <TableCell>Paragraph MM</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {news.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                {/* <TableCell>
                  <Avatar
                    alt="partner image"
                    src={`data:image/png;base64,${row.image_data}`}
                    width="56px"
                    height="56px"
                  />
                </TableCell> */}
                <TableCell>{row.title_en}</TableCell>
                <TableCell>{row.title_mm}</TableCell>
                <TableCell>{row.category}</TableCell>
                {/* <TableCell>{row.paragraph_en?.substring(10, 40)}</TableCell> */}
                <TableCell>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: row.paragraph_en?.substring(0, 40).trim(),
                    }}
                  ></div>
                </TableCell>
                {/* <TableCell>{row.paragraph_mm?.substring(10, 40)}</TableCell> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: row.paragraph_mm?.substring(0, 40),
                  }}
                ></div>

                <TableCell>
                  <Button
                    size="small"
                    component={Link}
                    color="secondary"
                    to={`/news/${row.id}`}
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
