import React, { useState } from "react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  TextField,
  FormControl,
  CardActions,
  CardMedia,
  Box,
  Typography,
  Card,
  CardContent,
  Breadcrumbs,
  Button,
  FormHelperText,
} from "@mui/material";
import bannerService from "../../../services/bannerService";
import { instanceToken } from "../../../utils/constant";
const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
export default function CreateBanner({ homeAlert }) {
  const navigate = useNavigate();
  const [nameEng, setNameEng] = useState("");
  const [nameMM, setNameMM] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [banners, setBanners] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const changeNameMM = (e) => {
    setNameMM(e.target.value);
  };
  const changeNameEng = (e) => {
    setNameEng(e.target.value);
  };
  const changeLink = (e) => {
    setLink(e.target.value);
  };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          image_data: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 204800) {
        setErrors({
          ...errors,
          image_data: "Image file size must be smaller than 200KB.",
        });
        return;
      }
      setPreview(URL.createObjectURL(img));
      setImage(img);
    }
  };

  const convertBase64 = (img) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(img);

      fileReader.onloadend = () => resolve(fileReader.result);
      fileReader.onerror = (err) => reject(err);
    });
  };
  const formData = new FormData();
  const handleCreate = async (e) => {
    e.preventDefault();

    formData.append("name_en", nameEng);
    formData.append("name_mm", nameMM);
    formData.append("link", link);
    formData.append("image_data", image);
    console.log("formData", formData);
    setErrors({});
    let err = {};
    formData.forEach((value, index) => {
      console.log("value", value);
      if (value === "") {
        err[index] = `${index} field is required`;
      }
    });
    console.log("error ", err);
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }

    try {
      setLoading(true);

      const response = await bannerService.postBanner(
        instanceToken.token,
        formData
      );
      navigate("/banners");
      setBanners(response.data);
      homeAlert("News banners have been created.", false);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/banners">Banners</Link>
          <span>Create Banners</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", pb: 0 }}>
            <Box sx={{ flex: 2, display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                height="320"
                image={preview}
                sx={{ my: 2 }}
              />

              <FormControl sx={{ my: 2 }}>
                <Typography
                  sx={{ fontSize: "12px", mb: 1, textAlign: "center" }}
                >
                  Rendered size must be 1920 * 1200 px and Aspect ratio must be
                  16: 10
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                  />
                </Button>
                <FormHelperText error>{errors["image_data"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                ml: "2rem",
              }}
            >
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_en"
                  label="Name Eng"
                  // value={values.name}
                  onChange={changeNameEng}
                  error={errors["name_en"]}
                  helperText={errors["name_en"]}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_mm"
                  label="name_mm"
                  //  value={values.phone}
                  onChange={changeNameMM}
                  error={errors["name_mm"]}
                  helperText={errors["name_mm"]}
                />
              </FormControl>

              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="link"
                  label="Link"
                  // value={values.address}
                  onChange={changeLink}
                  error={errors["link"]}
                  helperText={errors["link"]}
                />
              </FormControl>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </>
  );
}
