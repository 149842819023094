import React, { useEffect, useState } from "react";
import onboardingService from "../../../services/onboardings";
import { instanceToken } from "../../../utils/constant";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Breadcrumbs,
  Button,
  TableBody,
  Avatar,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import { TableRowsRounded } from "@mui/icons-material";

export default function Onboardings() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [onboardings, setOnboardings] = useState(null);

  useEffect(() => {
    fetchOnboarding(rowsPerPage, offset);
  }, [offset, rowsPerPage]);

  const fetchOnboarding = async (limit, offset) => {
    try {
      const res = await onboardingService.getOnboardings(
        instanceToken.token,
        limit,
        offset
      );

      parse(res.data);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    console.log("data are ", data);
    setOnboardings(data.startup_images);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!onboardings) {
    return <em>Loading</em>;
  }
  console.log("onboardings description ", onboardings);
  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <span>Onboarding</span>
        </Breadcrumbs>
      </div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          sx={{ my: 3 }}
          onClick={() => navigate("/createOnboarding")}
        >
          Create
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple-table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              {/* <TableCell>Image</TableCell> */}
              <TableCell>Name Eng</TableCell>
              <TableCell>Name MM</TableCell>
              <TableCell>Description Eng</TableCell>
              <TableCell>Description MM</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {onboardings.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                {/* <TableCell>
                  <Avatar
                    alt="routine image"
                    src={`data:image/png;base64,${row.image_data}`}
                    width="56px"
                    height="56px"
                  />
                </TableCell> */}
                <TableCell>{row.name_en}</TableCell>
                <TableCell>{row.name_mm}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.description_mm}</TableCell>
                <TableCell>
                  <a href={row.link} target="_parent">
                    {row.link.substring(0, 7)}
                  </a>
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    component={Link}
                    color="secondary"
                    to={`/onboardings/${row.id}`}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
