import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getKnowledges = async (token, limit, offset) => {
  console.log("offset is ".offset);
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/knowledges?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const getKnowledge = async (token, id) => {
  console.log("id ", id);
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/knowledges/${id}`,
    config
  );
  return response;
};

// const putKnowledge = async (token, id, data) => {
//   // const config = {
//   //   headers: { authorization: `Bearer ${token}` },
//   // };
//   const response = await axios.put(`${BACKEND_URL}api/Knowledges/${id}`, data);
//   return response;
// };

const postKnowledge = async (token, data) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const response = await axios.post(
    `${BACKEND_URL}api/knowledges`,
    data,
    config
  );
  return response;
};

const deleteKnowledge = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}api/Knowledges/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getKnowledges,
  getKnowledge,
  postKnowledge,
  deleteKnowledge,
};
