import React, { useState, useEffect, createContext, Suspense } from "react";
import Header from "../layouts/Header";
import SideBar from "../layouts/SideBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import { Alert } from "@mui/material";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { initToken } from "../utils/constant";
import authService from "../services/auth";

import AllNews from "./auth/newsPost/AllNews";
import News from "./auth/newsPost/News";
import CreateNews from "./auth/newsPost/CreateNewPost";

import Knowledges from "./auth/knowledges/Knowledges";
import CreateKnowledge from "./auth/knowledges/CreateKnowledge";
import Knowledge from "./auth/knowledges/Knowledge";
import UpdateKnowledge from "./auth/knowledges/UpdateKnowledge";

import Privileges from "./auth/partnership_hospital/Partners";
import Privilege from "./auth/partnership_hospital/Partner";
import CreatePrivilege from "./auth/partnership_hospital/CreatePartner";

import Emergencies from "./auth/emergencies/Emergencies";
import Emergencie from "./auth/emergencies/Emergencie";
import CreateEmergencies from "./auth/emergencies/CreateEmergencies";

import Banners from "./auth/banners/Banners";
import Banner from "./auth/banners/Banner";
import CreateBanner from "./auth/banners/CreateBanner";

import Onboardings from "./auth/onboardings/Onboardings";
import Onboarding from "./auth/onboardings/Onboarding";
import CreateOnboarding from "./auth/onboardings/CreateOnboarding";

import ClaimFiles from "./auth/claimFiles/ClaimFiles";
import ClaimFile from "./auth/claimFiles/ClaimFile";

import Users_level_2 from "./auth/user_level_2/Users2";
import User_level_2 from "./auth/user_level_2/User2";

import Users_level_3 from "./auth/userLevel3/User3s";
import User_level_3 from "./auth/userLevel3/User3";

import Inquirys from "./auth/inquiry/inquirys";
import Inquiry from "./auth/inquiry/inquiry";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    height: "100vh",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Home() {
  const navigate = useNavigate();
  const AuthContext = createContext();
  const [auth, setAuth] = useState(null);
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  const homeAlert = (message, isError = false) => {
    setShowAlert({ message: message, isError: isError });
    setTimeout(() => {
      setShowAlert({ message: "", isError: false });
    }, 4000);
  };

  useEffect(() => {
    const loggedJSON = window.localStorage.getItem("cblife_logged_admin");
    if (loggedJSON) {
      const parsedJSON = JSON.parse(loggedJSON);
      console.log("parse json ", parsedJSON);
      fetchAuth(parsedJSON.token).then(() => {
        initToken(parsedJSON.token, parsedJSON.role);
      });
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAuth = async (token) => {
    try {
      const res = await authService.getAuth(token);
      setAuth(res.data);
    } catch (error) {
      if (
        error?.response.data.error === "token invalid" ||
        error?.response.data.error === "token expired" ||
        error?.status === 403
      ) {
        navigate("/login");
      }
    }
  };

  if (!auth) {
    return <div>Loading...</div>;
  }
  console.log("auth ", auth);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const updateAuth = (data) => {
    setAuth(data);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        user={auth}
        updateUser={updateAuth}
        homeAlert={homeAlert}
      />
      <SideBar handleDrawerClose={handleDrawerClose} open={open} auth={auth} />
      <Main open={open}>
        <DrawerHeader />
        <Suspense fallback={<div>Loading...</div>}>
          <AuthContext.Provider value={auth}>
            <Routes>
              {auth.username === "admin-1" && (
                <>
                  {/* News */}
                  <Route path="/all_news" element={<AllNews />} />
                  <Route
                    path="/news/:id"
                    element={<News homeAlert={homeAlert} />}
                  />

                  <Route
                    path="/createNew"
                    element={<CreateNews homeAlert={homeAlert} />}
                  />

                  {/* emergencies */}
                  <Route path="/emergencies" element={<Emergencies />} />
                  <Route
                    path="/emergencies/:id"
                    element={<Emergencie homeAlert={homeAlert} />}
                  />

                  <Route
                    path="/createEmergencies"
                    element={<CreateEmergencies homeAlert={homeAlert} />}
                  />

                  {/* Knowledge */}
                  <Route path="/knowledges" element={<Knowledges />} />
                  <Route
                    path="/knowledges/:id"
                    element={<Knowledge homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/createKnowledge"
                    element={<CreateKnowledge homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/updateKnowledge/:id"
                    element={<UpdateKnowledge homeAlert={homeAlert} />}
                  />

                  {/* ParnersipHospital */}
                  <Route path="/privileges" element={<Privileges />} />
                  <Route
                    path="/privilege/:id"
                    element={<Privilege homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/createPartner"
                    element={<CreatePrivilege homeAlert={homeAlert} />}
                  />

                  {/* banners */}
                  <Route path="/banners" element={<Banners />} />
                  <Route
                    path="/banners/:id"
                    element={<Banner homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/createBanner"
                    element={<CreateBanner homeAlert={homeAlert} />}
                  />

                  {/* On boarding */}

                  <Route path="/onboardings" element={<Onboardings />} />
                  <Route
                    path="/onboardings/:id"
                    element={<Onboarding homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/createOnboarding"
                    element={<CreateOnboarding homeAlert={homeAlert} />}
                  />

                  {/* Claim Files */}
                  <Route path="/" element={<ClaimFiles />} />
                  <Route
                    path="/claimFiles/:claim_form_id"
                    element={<ClaimFile homeAlert={homeAlert} />}
                  />

                  {/* User Level 2 */}

                  <Route path="/users2" element={<Users_level_2 />} />
                  <Route
                    path="/users2/:id"
                    element={<User_level_2 homeAlert={homeAlert} />}
                  />
                  {/* User Level 3 */}

                  <Route path="/users3" element={<Users_level_3 />} />
                  <Route
                    path="/users3/:id"
                    element={<User_level_3 homeAlert={homeAlert} />}
                  />
                  {/* Inquiry */}
                  <Route path="/inquiry" element={<Inquirys />} />
                  <Route
                    path="/inquiry/:id"
                    element={<Inquiry homeAlert={homeAlert} />}
                  />
                </>
              )}

              {auth.username === "marketing" && (
                <>
                  {/* News */}
                  <Route path="/all_news" element={<AllNews />} />
                  <Route
                    path="/news/:id"
                    element={<News homeAlert={homeAlert} />}
                  />

                  <Route
                    path="/createNew"
                    element={<CreateNews homeAlert={homeAlert} />}
                  />

                  {/* emergencies */}
                  <Route path="/emergencies" element={<Emergencies />} />
                  <Route
                    path="/emergencies/:id"
                    element={<Emergencie homeAlert={homeAlert} />}
                  />

                  <Route
                    path="/createEmergencies"
                    element={<CreateEmergencies homeAlert={homeAlert} />}
                  />

                  {/* Knowledge */}
                  <Route path="/knowledges" element={<Knowledges />} />
                  <Route
                    path="/knowledges/:id"
                    element={<Knowledge homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/createKnowledge"
                    element={<CreateKnowledge homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/updateKnowledge/:id"
                    element={<UpdateKnowledge homeAlert={homeAlert} />}
                  />

                  {/* ParnersipHospital */}
                  <Route path="/privileges" element={<Privileges />} />
                  <Route
                    path="/privilege/:id"
                    element={<Privilege homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/createPartner"
                    element={<CreatePrivilege homeAlert={homeAlert} />}
                  />

                  {/* banners */}
                  <Route path="/banners" element={<Banners />} />
                  <Route
                    path="/banners/:id"
                    element={<Banner homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/createBanner"
                    element={<CreateBanner homeAlert={homeAlert} />}
                  />

                  {/* On boarding */}
                  <Route path="/onboardings" element={<Onboardings />} />
                  <Route
                    path="/onboardings/:id"
                    element={<Onboarding homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/createOnboarding"
                    element={<CreateOnboarding homeAlert={homeAlert} />}
                  />
                </>
              )}
            </Routes>
          </AuthContext.Provider>
        </Suspense>
      </Main>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </Box>
  );
}
