import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Grid,
  Box,
  Typography,
  CardContent,
  CardActions,
  Button,
  Breadcrumbs,
  Modal,
  CardMedia,
} from "@mui/material";
import uploadService from "../../../services/upload";
// import userService from "../../../services/partners";
import { instanceToken } from "../../../utils/constant";
import axios from "axios";
import inquirySevice from "../../../services/inquirySevice";
const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Inquiry = ({ homeAlert }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inquiry, setInquiry] = useState(null);
  const [openR, setOpenR] = useState(false);

  useEffect(() => {
    if (!inquiry) {
      fetchInquiry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInquiry = async () => {
    try {
      const res = await inquirySevice.getOneInquiry(instanceToken.token, id);
      setInquiry(res.data);
      console.log("response dsssss", res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!inquiry) {
    return <em>Loading...</em>;
  }

  const handleOpenR = () => setOpenR(true);
  const handleCloseR = () => setOpenR(false);
  const handleRemove = async () => {
    try {
      await inquirySevice.deleteInquiry(instanceToken.token, id);
      homeAlert("Inquiry post have been removed.", false);
      navigate("/inquiry");
    } catch (error) {
      console.log(error);
      homeAlert("Error on server!", true);
    }
  };

  const EnableInquiry = async () => {
    try {
      const res = await inquirySevice.updateInquiry(instanceToken.token, id);
      console.log(res.data);
      setInquiry(res.data);
    } catch (error) {
      console.log(error);

      homeAlert("Error on server!", true);
    }
  };

  const DisableInquiry = async () => {
    try {
      const res = await inquirySevice.updateInquiry(instanceToken.token, id);
      setInquiry(res.data);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server!", true);
    }
    // window.location.reload(false);
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/inquiry">
            Inquiry
          </Link>
          <Typography color="text.primary">Inquiry (ID - {id})</Typography>
        </Breadcrumbs>
      </div>
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Card
          sx={{
            minWidth: "80vw",
            minHeight: "60vh",
            display: "flex",
            flexDirection: "column",
            // gridTemplateColumns: "2fr 3fr",
          }}
        >
          <Box
            sx={{
              ml: 2,
              p: 2,
              // bgcolor: "#f7f7f5",
              borderRadius: 2,
            }}
          >
            {/* ID */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  ID:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {inquiry.id}
                </Typography>
              </Grid>
            </Grid>
            {/* product name */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Product Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {inquiry.product_name}
                </Typography>
              </Grid>
            </Grid>
            {/* Username */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  User name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {inquiry.user_name}
                </Typography>
              </Grid>
            </Grid>
            {/* phone number */}
            <Grid sx={{ m: 2 }} container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="body2" color="text.secondary">
                  Phone Number:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  {inquiry.phone_number}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <CardActions
            sx={{ gridColumn: "1/3", justifyContent: "space-between", px: 8 }}
          >
            {inquiry.service_request_checked ? (
              <Button
                variant="contained"
                color="primary"
                onClick={EnableInquiry}
              >
                Completed
              </Button>
            ) : (
              <Button
                variant="contained"
                color="error"
                onClick={DisableInquiry}
              >
                Revert
              </Button>
            )}
            <Button size="small" color="error" onClick={handleOpenR}>
              Remove
            </Button>
          </CardActions>
        </Card>
      </Container>
      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={handleRemove}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Inquiry;
