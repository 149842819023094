import React from "react";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLink } from "react-router-dom";
import logo from "../assets/icons/cblifelogo.png";
import icons from "../views/icons";

let activeStyle = {
  textDecoration: "underline",
};

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBar = ({ handleDrawerClose, open, auth }) => {
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mr: 3,
          }}
        >
          <img src={logo} alt="cblife" height="60px" />
        </Box>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box sx={{ overflow: "auto" }}>
        <Divider />
        <List>
          {/* dashboard */}
          {/* <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            to="/"
            className="nav-link"
          >
            <ListItem disablePadding className="nav-btn">
              <ListItemButton>
                <ListItemIcon>
                  <img
                    src={icons.dashboard}
                    className="nav-link-icon"
                    alt="dashboard"
                  ></img>
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItemButton>
            </ListItem>
          </NavLink> */}
          {auth.username === "admin-1" && (
            <>
              {/* all_news */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/all_news"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.news}
                        className="nav-link-icon"
                        alt="new post"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"News Posts"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Knowledges */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/knowledges"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.knowledge}
                        className="nav-link-icon"
                        alt="knowledges"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Knowledges"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Privileges */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/privileges"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.privilege}
                        className="nav-link-icon"
                        alt="privilege"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Privilege"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>

              {/* Banners */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/banners"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.onboarding}
                        className="nav-link-icon"
                        alt="banner"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Banners"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Emergency */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/emergencies"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.emergency}
                        className="nav-link-icon"
                        alt="emergency"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Emergencies"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>

              {/* Onboarding */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/onboardings"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.onboarding}
                        className="nav-link-icon"
                        alt="onboardings"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Onboardings"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>

              {/* ClaimFiles */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.claimForm}
                        className="nav-link-icon"
                        alt="claimFiles"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"ClaimFiles"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>

              {/* Users 2 */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/users2"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.user}
                        className="nav-link-icon"
                        alt="users2"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"User Level 2"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Users 3 */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/users3"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.user}
                        className="nav-link-icon"
                        alt="users3"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"User Level 3"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Inquiry */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/inquiry"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.inquirys}
                        className="nav-link-icon"
                        alt="inquiry"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Inquiry"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </>
          )}

          {auth.username === "marketing" && (
            <>
              {/* all_news */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/all_news"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.news}
                        className="nav-link-icon"
                        alt="new post"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"News Posts"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Knowledges */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/knowledges"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.knowledge}
                        className="nav-link-icon"
                        alt="knowledges"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Knowledges"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Privileges */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/privileges"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.privilege}
                        className="nav-link-icon"
                        alt="privilege"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Privilege"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>

              {/* Banners */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/banners"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.onboarding}
                        className="nav-link-icon"
                        alt="banner"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Banners"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Emergency */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/emergencies"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.emergency}
                        className="nav-link-icon"
                        alt="emergency"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Emergencies"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>

              {/* Onboarding */}
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/onboardings"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      {/* <DashboardIcon className="nav-link-icon" /> */}
                      <img
                        src={icons.onboarding}
                        className="nav-link-icon"
                        alt="onboardings"
                      ></img>
                    </ListItemIcon>
                    <ListItemText primary={"Onboardings"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
