import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import partnerService from "../../../services/partners";
import uploadService from "../../../services/upload";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  FormControl,
  TextField,
  FormHelperText,
  Button,
  Typography,
  CardMedia,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import RichTextEditor from "react-rte";
import { instanceToken } from "../../../utils/constant";
import axios from "axios";
import { Category } from "@mui/icons-material";
import partners from "../../../services/partners";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

export default function CreatePrivilege({ homeAlert }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isImageChange, setIsImageChange] = useState(false);

  const [nameEng, setNameEng] = useState("");
  const [nameMM, setNameMM] = useState("");
  const [addressEng, setAddressEng] = useState("");
  const [addressMM, setAddressMM] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNote, setPhoneNote] = useState("");
  const [link, setLink] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [parEng, setParEng] = useState("");
  const [parMM, setParMM] = useState("");

  const [errors, setErrors] = useState({});

  const [partner, setPartner] = useState(null);
  const [formData, setFormData] = useState(new FormData());

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files && e.target.files[0]) {
        const img = e.target.files[0];
        if (!imgFileTypes.includes(img.type)) {
          setErrors({
            ...errors,
            image_data: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
          });
          return;
        }
        if (img.size > 204800) {
          setErrors({
            ...errors,
            image_data: "Image file size must be smaller than 200KB.",
          });
          return;
        }
        setPreview(URL.createObjectURL(img));
        setImage(img);
        setIsImageChange(true);
      }
    }
  };

  const handleCreate = async () => {
    const formData = new FormData();
    formData.append("name_en", nameEng);
    formData.append("name_mm", nameMM);
    formData.append("address_en", addressEng);
    formData.append("address_mm", addressMM);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("phone_note", phoneNote);
    formData.append("link", link);
    formData.append("image_data", image);
    formData.append("category", category);
    formData.append("description_en", parEng);
    formData.append("description_mm", parMM);

    setErrors({});
    let err = {};

    formData.forEach((value, index) => {
      console.log("value", value);
      if (value === "") {
        err[index] = `${index} field is required`;
      }
    });

    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      const response = await partners.postPartner(
        instanceToken.token,
        formData
      );
      setPartner(response.data);
      navigate("/privileges");
      homeAlert("New Privilege have been created.", false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };
  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/privileges">Privilege</Link>
          <span>Create Privilege</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", pb: 0, flexDirection: "column" }}>
            <Box
              sx={{
                maxWidth: "40vw",
                display: "grid",
                justifyContent: "center",
                // bgcolor: "red",
                ml: 31,
                borderRadius: 2,
                boxShadow: 2,
              }}
            >
              <CardMedia
                component="img"
                height="320"
                image={preview}
                sx={{ my: 2 }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {/* image */}
              <FormControl sx={{ my: 2, maxWidth: 400 }}>
                <Typography
                  sx={{
                    mb: 1,
                    fontSize: "12px",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  Rendered size must be 340 * 180 px , Aspect ratio must be
                  1.8:1 and resolution must be 72ppi
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                    error={errors["image_data"]}
                    helperText={errors["image_data"]}
                  />
                </Button>
                {/* {errors.category && (
                  <FormHelperText error>{errors.category}</FormHelperText>
                )} */}
                <FormHelperText error>{errors["image_data"]}</FormHelperText>
              </FormControl>
            </Box>
            {/* name */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* name Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="name_en"
                  label="Name Eng"
                  value={nameEng}
                  onChange={(e) => setNameEng(e.target.value)}
                  error={errors["name_en"]}
                  helperText={errors["name_en"]}
                />
              </FormControl>
              {/* Nmae MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_mm"
                  label="Name MM"
                  value={nameMM}
                  onChange={(e) => setNameMM(e.target.value)}
                  error={errors["name_mm"]}
                  helperText={errors["name_mm"]}
                />
              </FormControl>
            </Box>
            {/* address */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* address Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="address_en"
                  label="Address Eng"
                  value={addressEng}
                  onChange={(e) => setAddressEng(e.target.value)}
                  error={errors["address_en"]}
                  helperText={errors["address_en"]}
                />
              </FormControl>
              {/* Address MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="address_mm"
                  label="Address MM"
                  value={addressMM}
                  onChange={(e) => setAddressMM(e.target.value)}
                  error={errors["address_mm"]}
                  helperText={errors["address_mm"]}
                />
              </FormControl>
            </Box>
            {/* description */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* description Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="description_en"
                  label="Description Eng"
                  value={parEng}
                  onChange={(e) => setParEng(e.target.value)}
                  error={errors["description_en"]}
                  helperText={errors["description_en"]}
                />
              </FormControl>
              {/* description MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="description_mm"
                  label="Description MM"
                  value={parMM}
                  onChange={(e) => setParMM(e.target.value)}
                  error={errors["description_mm"]}
                  helperText={errors["description_mm"]}
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* phone*/}
              <FormControl sx={{ my: 2, maxWidth: 800 }}>
                <TextField
                  id="phone"
                  label="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  error={errors["phone"]}
                  helperText={errors["phone"]}
                />
              </FormControl>

              {/* Email*/}
              <FormControl sx={{ my: 2, maxWidth: 800 }}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={errors["email"]}
                  helperText={errors["email"]}
                />
              </FormControl>
              {/* phoneNote */}
              <FormControl sx={{ my: 2 }}>
                <TextField
                  id="phone_note"
                  label="Phone Note"
                  value={phoneNote}
                  onChange={(e) => setPhoneNote(e.target.value)}
                  error={errors["phone_note"]}
                  helperText={errors["phone_note"]}
                />
              </FormControl>

              {/* link */}
              <FormControl sx={{ my: 2 }}>
                <TextField
                  id="link"
                  label="Link"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  error={errors["link"]}
                  helperText={errors["link"]}
                />
              </FormControl>
              {/* Category */}
              <FormControl sx={{ my: 2 }}>
                <InputLabel id="category">Category</InputLabel>
                <Select
                  labelId="category"
                  label="category"
                  defaultValue=""
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setErrors({});
                  }}
                  //error={errors.category ? true : false}
                >
                  <MenuItem value="" disabled>
                    Value
                  </MenuItem>
                  <MenuItem value="Hospital">Hospital_&_Clinic</MenuItem>
                  <MenuItem value="Education">Education</MenuItem>
                  <MenuItem value="Fitness">Fitness</MenuItem>
                  <MenuItem value="Hotel">Hotel</MenuItem>
                </Select>
                {/* {errors.category && (
                  <FormHelperText error>{errors.category}</FormHelperText>
                )} */}
                <FormHelperText error>{errors["category"]}</FormHelperText>
              </FormControl>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </>
  );
}
