import dashboard from "../assets/icons/dashboard.png";
import news from "../assets/icons/news.png";
import knowledge from "../assets/icons/knowledge.png";
import promotion from "../assets/icons/promotions.png";
import onboarding from "../assets/icons/onboarding.png";
import product from "../assets/icons/products.png";
import privilege from "../assets/icons/partnership.png";
import hospital from "../assets/icons/hospital.png";
import emergency from "../assets/icons/emergency.png";
import claimForm from "../assets/icons/claimForm.png";
import user from "../assets/icons/user.png";
import inquirys from "../assets/icons/inquirys.png";

export default {
  dashboard,
  news,
  knowledge,
  product,
  promotion,
  privilege,
  hospital,
  onboarding,
  emergency,
  claimForm,
  user,
  inquirys,
};
